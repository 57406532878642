import { useEffect } from 'react';

interface GlobalSettingsOptions {
  title?: string;
  faviconUrl?: string;
}

export const useGlobalSettings = ({
  title,
  faviconUrl,
}: GlobalSettingsOptions) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }

    if (faviconUrl) {
      const link: HTMLLinkElement | null =
        document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = faviconUrl;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = faviconUrl;
        document.head.appendChild(newLink);
      }
    }
  }, [title, faviconUrl]);
};
