import { gql } from '@apollo/client';

export const GET_WHITE_LABEL_CONFIG_QUERY = gql`
  query getWhiteLabelConfig {
    getWhiteLabelConfig {
      favIconPath
      sidebarLogoPath
      mainLogoPath
      brandName
      backgroundColorHexCode
      footerSign
      contactEmail
      notificationEmail
      userHandbookLink
      scriptsJSON
      userAgreementLink
      personalDataLink
      companyDetails
    }
  }
`;
