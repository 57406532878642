import { Box } from '@material-ui/core';
import React from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikValues } from 'formik';
import styles from './styles';

import SaveIcon from '@material-ui/icons/Save';
import InputInstalation from '../InputInstalation';
import UploadProductPhotoComponent from '../UploadProductPhoto/UploadProductPhotoComponent';
import { useTranslation } from 'react-i18next';
import { globalSettingsSchema } from '../../../utils/validationSchemes';
import {
  useGetWhiteLabelConfigQuery,
  useSetWhiteLabelConfigMutation,
} from '../../../generated/graphql';
import { GET_WHITE_LABEL_CONFIG_QUERY } from '../../../GraphQL/queries/getWhiteLabelConfig';
import { useSnackbar } from 'notistack';
import { getLebalFileUrl } from '../../../utils/getLebalFileUrl';

export const MainForm = () => {
  const { t } = useTranslation();
  const { data } = useGetWhiteLabelConfigQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [setConfigMutation, { loading }] = useSetWhiteLabelConfigMutation({
    onCompleted: () => {
      enqueueSnackbar(t('app.configUpdateSuccess'), { variant: 'success' });
    },
    refetchQueries: [GET_WHITE_LABEL_CONFIG_QUERY],
  });

  const whiteLabelData = data?.getWhiteLabelConfig;

  const classes = styles();

  const initialValues = {
    brandName: whiteLabelData?.brandName || '',
    mainLogo: whiteLabelData
      ? getLebalFileUrl(whiteLabelData?.mainLogoPath || '')
      : '',
    sidebarLogo: whiteLabelData?.sidebarLogoPath
      ? getLebalFileUrl(whiteLabelData?.sidebarLogoPath)
      : '',
    favIcon: whiteLabelData?.favIconPath
      ? getLebalFileUrl(whiteLabelData?.favIconPath)
      : '',
    backgroundColorHexCode: whiteLabelData?.backgroundColorHexCode || '',
    footerSign: whiteLabelData?.footerSign || '',
    contactEmail: whiteLabelData?.contactEmail || '',
    notificationEmail: whiteLabelData?.notificationEmail || '',
    userHandbookLink: whiteLabelData?.userHandbookLink || '',
    scriptsJSON: whiteLabelData?.scriptsJSON
      ? JSON.parse(whiteLabelData?.scriptsJSON)
      : '',
    personalDataLink: whiteLabelData?.personalDataLink || '',
    userAgreementLink: whiteLabelData?.userAgreementLink || '',
    companyDetails: whiteLabelData?.companyDetails || '',
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = Object.entries(values).reduce((acc, [key, value]) => {
      if (
        !['mainLogo', 'sidebarLogo', 'favIcon'].includes(key) ||
        typeof value !== 'string'
      ) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

    setConfigMutation({
      variables: {
        data: {
          ...formValues,
          scriptsJSON: JSON.stringify(formValues.scriptsJSON),
        },
      },
    });
  };

  return (
    <Box mb='20px'>
      <Typography variant='h2' align='center' className={classes.title}>
        {t('app.installationSettings')}
      </Typography>

      <Divider className={classes.titleDivider} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={globalSettingsSchema}
      >
        {() => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.brandName')}
                    name='brandName'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <UploadProductPhotoComponent
                    name='mainLogo'
                    label={t('app.mainLogo')}
                    width={200}
                    height={91}
                  />
                </Grid>

                <Grid item xs={12}>
                  <UploadProductPhotoComponent
                    name='sidebarLogo'
                    label={t('app.sidebarLogo')}
                    width={45}
                    height={45}
                  />
                </Grid>

                <Grid item xs={12}>
                  <UploadProductPhotoComponent
                    name='favIcon'
                    label={t('app.favicon')}
                    width={45}
                    height={45}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.bgColor')}
                    name='backgroundColorHexCode'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.personalDataProcessingLink')}
                    name='personalDataLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.userAgreementLink')}
                    name='userAgreementLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.footerSignature')}
                    name='footerSign'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.contactEmail')}
                    name='contactEmail'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.notificationEmail')}
                    name='notificationEmail'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.userHandbookLink')}
                    name='userHandbookLink'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.companyDetails')}
                    name='companyDetails'
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.scripts')}
                    name='scriptsJSON'
                    fullWidth
                    multiline
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    startIcon={
                      loading ? <CircularProgress size={22} /> : <SaveIcon />
                    }
                  >
                    {t('app.save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
