import React, { memo, useEffect, useState } from 'react';
import { Button, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { IFilters } from '../../../utils/types';
import { useDebounce } from 'use-debounce';

const FilterInputSearch: React.FC<{
  setFilter: React.Dispatch<React.SetStateAction<IFilters>>;
  autoFocus?: boolean;
  isHasSubmitBtn?: boolean;
  marginLeft?: number | string;
}> = ({ setFilter, autoFocus, isHasSubmitBtn = true, marginLeft = 'auto' }) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const [debounceSearch] = useDebounce(search, 1000);

  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };
  const startSearch = (e: any) => {
    e.preventDefault();

    setFilter((state: any) => ({
      ...state,
      search: search,
      page: 1,
    }));
  };

  useEffect(() => {
    !isHasSubmitBtn &&
      setFilter((state: any) => ({
        ...state,
        search: debounceSearch,
        page: 1,
      }));
  }, [debounceSearch, setFilter, isHasSubmitBtn]);

  return (
    <form
      className={classes.filtersSearch}
      onSubmit={startSearch}
      style={{ marginLeft }}
    >
      <Input
        placeholder={t('app.enterText')}
        className={classes.filtersSearchField}
        onChange={handleChange}
        value={search}
        disableUnderline
        autoFocus={autoFocus}
      />
      {isHasSubmitBtn ? (
        <Button
          className={classes.filtersSearchSubmit}
          variant='contained'
          type='submit'
          aria-label='search'
        >
          <SearchIcon />
        </Button>
      ) : null}
    </form>
  );
};

export default memo(FilterInputSearch);
