import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import {
  LOCAL_STORAGE_KEYS,
  PRODUCTS_LIST_TABLE_CELLS_ARRAY,
} from '../../../utils/constants';
import {
  Product,
  ProductSort,
  useExportFullProductsInXlsxDocLazyQuery,
  useGetCurrentUserQuery,
  useGetMyProductListLazyQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { LIMIT_ITEM_PAGE } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import FilterSelectWarehouse from '../../../components/FilterComponents/FilterSelectWarehouse/FilterSelectWarehouse';
import { IFilters } from '../../../utils/types';
import FilterSelectLimitItems from '../../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import FilterInputSearch from '../../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import FilterSelectSortingProducts from '../../../components/FilterComponents/FilterSelectSortingProducts/FilterSelectSortingProducts';
import FilterSelectBySortType from '../../../components/FilterComponents/FilterSelectBySortType/FilterSelectBySortType';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent';
import TableBodyRequestHandler from '../../../components/TableBodyRequestHandler/TableRequestHandler';
import ProductsListTableRowComponent from '../../../components/ProductsListComponents/ProductsListTableRowComponent/ProductsListTableRowComponent';

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);
const warehouseId = localStorage.getItem(LOCAL_STORAGE_KEYS.WAREHOUSE_ID);

const MyProductList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [products, setProducts] = useState<Product[]>([]);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilters>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '',
    search: '',
  });

  const [productFilter, setProductFilter] = useState<ProductSort>({});

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [exportQuery] = useExportFullProductsInXlsxDocLazyQuery({
    onCompleted: ({ exportFullProductsInXLSXDoc }) => {
      const fileUrl = `${process.env.REACT_APP_API_DOMAIN}/api/getProductFile/${exportFullProductsInXLSXDoc}`;
      window.open(fileUrl, '_blank');
    },
  });

  const [getMyProductListLazyQuery, { data: dataProducts, loading }] =
    useGetMyProductListLazyQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const getExportQuery = () => {
    const variables = filter.warehouseId
      ? {
          warehouseId: Number(filter.warehouseId),
        }
      : {};

    exportQuery({
      variables: variables,
    });
  };

  useEffect(() => {
    if (!dataProducts?.getMyProductList?.rows) return;

    dataProducts?.getMyProductList?.rows &&
      setProducts(dataProducts?.getMyProductList?.rows as Product[]);

    // eslint-disable-next-line
  }, [dataProducts]);

  useEffect(() => {
    refetchMyProductList();
    // eslint-disable-next-line
  }, [filter, productFilter]);

  const refetchMyProductList = () => {
    getMyProductListLazyQuery({
      variables: {
        limit: filter.itemsLimit,
        offset: (filter.page - 1) * filter.itemsLimit!,
        search: filter.search,
        ...(filter.warehouseId &&
          currentUserData?.currentUser?.role === 'SUPER_ADMIN' && {
            warehouseId: filter.warehouseId,
          }),
        sort: productFilter,
      },
    });
  };

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {(t('app.productsWarehouse') || '').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
          <FilterSelectWarehouse
            value={filter.warehouseId as string}
            setFilter={setFilter}
          />
        ) : null}
        <FilterSelectSortingProducts
          value={productFilter.type}
          setFilter={setProductFilter}
        />
        {productFilter.type ? (
          <FilterSelectBySortType
            value={productFilter.way}
            setFilter={setProductFilter}
          />
        ) : null}
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT}
        />

        <Button
          variant='contained'
          style={{ marginRight: 24, marginLeft: 'auto' }}
          onClick={getExportQuery}
        >
          {t('app.uploadData')}
        </Button>
        <FilterInputSearch setFilter={setFilter} marginLeft={0} />
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {PRODUCTS_LIST_TABLE_CELLS_ARRAY.map((cell) => (
                <TableCell align='center'>{t(cell)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={Number(products?.length)}
              colSpan={PRODUCTS_LIST_TABLE_CELLS_ARRAY.length}
              loadingText={t('app.products').toLowerCase()}
              loading={loading}
            >
              {products
                ? products.map((product) => (
                    <ProductsListTableRowComponent product={product} />
                  ))
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationComponent
        count={dataProducts?.getMyProductList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />
    </Container>
  );
};

export default MyProductList;
