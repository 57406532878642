import React from 'react';
import { Container } from '@material-ui/core';
import { MainForm } from '../../../components/SettingsInstallationComponents/MainForm/MainForm';
import { ChangePasswordForm } from '../../../components/SettingsInstallationComponents/ChangePasswordForm/ChangePasswordForm';

const SettingsInstallation = () => {
  return (
    <Container>
      <MainForm />

      <ChangePasswordForm />
    </Container>
  );
};

export default SettingsInstallation;
