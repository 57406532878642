import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { TProps } from './types';
import { useTranslation } from 'react-i18next';

const InputInstalation = ({
  classes,
  label,
  name,
  inputProps,
  startAdornment,
  endAdornment,
  fullWidth = false,
  multiline = false,
  type = 'text',
}: TProps) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {label}
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field name={name}>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl error={meta.touched && !!meta.error}>
              <Input
                disableUnderline
                id={name}
                defaultValue={value}
                className={fullWidth ? '' : classes.inputNumber}
                {...field}
                startAdornment={startAdornment}
                endAdornment={
                  type === 'password' ? (
                    <InputAdornment
                      position='end'
                      onClick={() => setIsShowPassword((prev) => !prev)}
                    >
                      <IconButton aria-label='toggle password visibility'>
                        {isShowPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ) : undefined
                }
                inputProps={inputProps}
                onChange={(event) => {
                  setFieldValue(name, event.target.value);
                }}
                multiline={multiline}
                type={!isShowPassword ? type : 'text'}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{t(meta.error)}</FormHelperText>
              )}
            </FormControl>
          )}
        </Field>
      </Grid>
    </Grid>
  );
};

export default InputInstalation;
