import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  B2BOrder,
  B2BServices,
  useGetB2BShipmentServicesQuery,
} from '../../../generated/graphql';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import { B2B_PACKAGING_SERVICE_TABLE_CELLS_ARRAY } from '../../../utils/constants';
import {
  FastField,
  FieldArray,
  FieldArrayRenderProps,
  FieldProps,
  useFormikContext,
} from 'formik';
import { IB2bShipmentPackagingFromikProps } from './types';
import { useSnackbar } from 'notistack';
import {
  Add as AddIcon,
  DeleteForever as DeleteIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';

const B2bShipmentPackagingStep4 = ({
  hadleSaveServices,
  loading,
}: {
  hadleSaveServices: (values: B2BOrder) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectOptions, setSelectOptions] = useState<B2BServices[]>([]);

  const { values, setFieldValue } =
    useFormikContext<IB2bShipmentPackagingFromikProps>();

  const { data, loading: servicesLoading } = useGetB2BShipmentServicesQuery({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!!data?.getB2BShipmentServices?.rows) {
      setSelectOptions(
        data?.getB2BShipmentServices?.rows.map((service) => ({
          id: service?.id,
          title: service?.title,
          costInUSD: service?.costInUSD,
          code: service?.code,
        })) as B2BServices[],
      );
    }
  }, [data]);

  const addServiceHandler = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({ id: 0, costInUSD: 0, qty: 1, title: '' });
  };

  const deleteServiceHandler =
    (arrayHelpers: FieldArrayRenderProps, index: number) => () => {
      arrayHelpers.remove(index);
    };

  const onChangeSelectHandler = (
    index: number,
    newValue: B2BServices | null,
  ) => {
    const id = newValue?.id || 0;
    const title = newValue?.title || '';
    const costInUSD = newValue?.costInUSD || 0;
    setFieldValue(`servicesWithCost.${index}.id`, id, false);
    setFieldValue(`servicesWithCost.${index}.costInUSD`, costInUSD, false);
    setFieldValue(`servicesWithCost.${index}.title`, title, false);
  };

  const totalCost = values.servicesWithCost?.reduce((acc, service) => {
    return acc + Number(service?.costInUSD) * Number(service?.qty);
  }, 0);

  const onSaveServices = () => {
    hadleSaveServices(values);
  };

  return (
    <div className={classes.stepContent}>
      <RequestHandler loading={servicesLoading}>
        <FieldArray name={'servicesWithCost'}>
          {(arrayHelpers) => (
            <>
              <Button
                onClick={addServiceHandler(arrayHelpers)}
                startIcon={<AddIcon />}
              >
                {t('app.service')}
              </Button>
              <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {B2B_PACKAGING_SERVICE_TABLE_CELLS_ARRAY.map((text) => (
                        <TableCell key={text} align='center' rowSpan={2}>
                          {t(text)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectOptions.length &&
                      values.servicesWithCost?.map((service, index) => (
                        <TableRow key={`${service?.id}-${index}`}>
                          <TableCell align='center'>
                            <FastField name={`servicesWithCost.${index}.title`}>
                              {({
                                field: { value, ...field },
                                meta,
                              }: FieldProps) => (
                                <FormControl
                                  error={meta.touched && !!meta.error}
                                >
                                  <Autocomplete
                                    fullWidth
                                    options={selectOptions}
                                    filterOptions={(options, { inputValue }) =>
                                      options.filter(
                                        (option) =>
                                          option?.title
                                            ?.toLowerCase()
                                            ?.includes(
                                              inputValue.toLowerCase(),
                                            ) ||
                                          option?.code
                                            ?.toLowerCase()
                                            ?.includes(
                                              inputValue.toLowerCase(),
                                            ),
                                      )
                                    }
                                    getOptionLabel={(option) => {
                                      return (
                                        `${option.code} ${option?.title}` || ''
                                      );
                                    }}
                                    value={
                                      selectOptions.find(
                                        (option) =>
                                          option.title ===
                                          values?.servicesWithCost?.[index]
                                            ?.title,
                                      ) || null
                                    }
                                    onChange={(event, newValue) => {
                                      onChangeSelectHandler(index, newValue);
                                    }}
                                    renderOption={(option) => {
                                      return (
                                        <>{`${option.code} ${option?.title}`}</>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <div ref={params.InputProps.ref}>
                                        <TextField
                                          {...params}
                                          inputProps={{
                                            ...params.inputProps,
                                            // autoComplete: 'my_services',
                                          }}
                                        />
                                      </div>
                                    )}
                                  />
                                  {meta.touched && !!meta.error && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            </FastField>
                          </TableCell>
                          <TableCell align='center'>
                            <FastField
                              name={`servicesWithCost.${index}.costInUSD`}
                            >
                              {({
                                field: { value, ...field },
                                meta,
                              }: FieldProps) => (
                                <FormControl
                                  style={{ width: '100px' }}
                                  error={meta.touched && !!meta.error}
                                >
                                  <Input
                                    disableUnderline
                                    id={`input-service-cost-id`}
                                    {...field}
                                    inputProps={{
                                      type: 'number',
                                      min: 0.01,
                                      step: 0.01,
                                    }}
                                    value={value}
                                  />
                                  {meta.touched && !!meta.error && (
                                    <FormHelperText>
                                      {meta.error}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            </FastField>
                          </TableCell>
                          <TableCell align='center'>
                            <FastField name={`servicesWithCost.${index}.qty`}>
                              {({
                                field: { value, ...field },
                                meta,
                              }: FieldProps) => (
                                <FormControl
                                  style={{ width: '100px' }}
                                  error={meta.touched && !!meta.error}
                                >
                                  <Input
                                    disableUnderline
                                    id={`input-service-quantity-id`}
                                    {...field}
                                    inputProps={{
                                      type: 'number',
                                      min: 1,
                                      step: 1,
                                    }}
                                    defaultValue={value}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `servicesWithCost.${index}.qty`,
                                        Number(e.target.value),
                                      );
                                    }}
                                    value={value}
                                  />
                                  {meta.touched && !!meta.error && (
                                    <FormHelperText>
                                      {t(meta.error)}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              )}
                            </FastField>
                          </TableCell>
                          <TableCell align='center'>
                            <Input
                              style={{ width: '100px' }}
                              disableUnderline
                              id={`input-service-total-id`}
                              value={(
                                Number(
                                  values?.servicesWithCost?.[index]?.costInUSD,
                                ) *
                                Number(values?.servicesWithCost?.[index]?.qty)
                              ).toFixed(2)}
                              disabled
                            />
                          </TableCell>
                          <TableCell align='center'>
                            <IconButton
                              size='small'
                              className={classes.btnIcon}
                              onClick={deleteServiceHandler(
                                arrayHelpers,
                                index,
                              )}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </FieldArray>
        {!!totalCost ? (
          <>
            <Box pb={1} pl={2} mt={4}>
              <Typography variant='h6'>{t('app.cost')}</Typography>
            </Box>
            {values?.servicesWithCost?.map((service) => {
              const totalItemCost =
                Number(service?.costInUSD) * Number(service?.qty);
              return totalItemCost ? (
                <ListItem className={classes.itemInfo}>
                  {`${service?.title}: ${totalItemCost.toFixed(2)} (${
                    service?.qty
                  })`}
                </ListItem>
              ) : null;
            })}
            <ListItem
              className={classes.itemInfo}
              style={{ fontWeight: 'bold' }}
            >
              {t('app.overall')} {totalCost.toFixed(2)}
            </ListItem>
          </>
        ) : null}
        {/* <Button
          className={clsx(classes.arrangeAction, classes.action)}
          type={'button'}
          variant='contained'
          onClick={onSaveServices}
          disabled={loading || !values?.servicesWithCost?.length}
          style={{ marginTop: '24px' }}
        >
          {t('app.save')}
        </Button> */}
      </RequestHandler>
    </div>
  );
};

export default B2bShipmentPackagingStep4;
