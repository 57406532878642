import React, { useCallback, useState } from 'react';
import useStyles from './styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import ParcelsFromWarehouse from '../ParcelsFromWarehouse';
import FilterInputSearch from '../../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import { IFilters } from '../../../utils/types';
import { tabsOptions } from './options';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`shipments-tabpanel-${index}`}
      aria-labelledby={`shipments-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `shipments-tab-${index}`,
    'aria-controls': `shipments-tabpanel-${index}`,
  };
}

export default function ParcelsTabs() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(
    Number(localStorage.getItem(LOCAL_STORAGE_KEYS.TAB_VALUE)) || 0,
  );
  const [filterTabs, setFilterTabs] = useState<IFilters>({
    search: '',
    page: 1,
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TAB_VALUE, newValue.toString());
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <div className={classes.root}>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.d2cShipments')}
      </Typography>
      <AppBar className={classes.tabs} color='inherit' position='static'>
        <Tabs value={value} onChange={handleChange} aria-label='shipments tabs'>
          {tabsOptions.map(({ label }, index) => (
            <Tab key={label} label={t(label)} {...a11yProps(index)} />
          ))}

          <FilterInputSearch
            setFilter={setFilterTabs}
            autoFocus
            isHasSubmitBtn={false}
          />
        </Tabs>
      </AppBar>
      {tabsOptions.map(({ type, label }, index) => (
        <TabPanel key={label} value={value} index={index}>
          <ParcelsFromWarehouse filterTabs={filterTabs} type={type} />
        </TabPanel>
      ))}
    </div>
  );
}
