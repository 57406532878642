import { Box } from '@material-ui/core';

import React from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, Formik, FormikValues } from 'formik';
import styles from './styles';

import SaveIcon from '@material-ui/icons/Save';
import InputInstalation from '../InputInstalation';
import { useTranslation } from 'react-i18next';
import { changePasswordSchema } from '../../../utils/validationSchemes';
import { useChangeSuperAdminPasswordMutation } from '../../../generated/graphql';
import { useSnackbar } from 'notistack';

export const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [resetPasswordMutation, { loading }] =
    useChangeSuperAdminPasswordMutation({
      onCompleted: () => {
        enqueueSnackbar(t('app.configUpdateSuccess'), { variant: 'success' });
      },
      onError: (err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      },
    });
  const classes = styles();

  const initialValues = {
    lastPass: '',
    newPass: '',
  };

  const handleSubmit = ({ lastPass, newPass }: FormikValues) => {
    resetPasswordMutation({
      variables: {
        lastPass,
        newPass,
      },
    });
  };

  return (
    <Box mb='20px'>
      <Typography variant='h2' align='center' className={classes.title}>
        {t('app.changePassword')}
      </Typography>

      <Divider className={classes.titleDivider} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={changePasswordSchema}
      >
        {() => {
          return (
            <Form noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.lastPassword')}
                    name='lastPass'
                    fullWidth
                    type={'password'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputInstalation
                    classes={classes}
                    label={t('app.newPassword')}
                    name='newPass'
                    fullWidth
                    type={'password'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    startIcon={
                      loading ? <CircularProgress size={22} /> : <SaveIcon />
                    }
                  >
                    {t('app.save')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
